import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/SinglePost/TopQuote"
import MainContent from "../components/SinglePost/MainContent"
import NextPost from "../components/SinglePost/NextPost"
import PrevPost from "../components/SinglePost/PrevPost"

const post = props => {
  const prevPost = props.pageContext.prev
  const nextPost = props.pageContext.next
  const { post } = props.data
  const _coo_toqu_title = post.title
  const _coo_toqu_content = post.acf._coo_post_excerpt

  const { allPosts } = props.data
  const prevPostData = allPosts.edges.find(post => {
    return post.node.slug === prevPost
  })
  const nextPostData = allPosts.edges.find(post => {
    return post.node.slug === nextPost
  })

  const intro = {
    acf: {
      _coo_toqu_title,
      _coo_toqu_content,
    },
  }

  const mainData = {
    wysiwyg: post.acf._coo_post_post_content,
    featureImg: post.acf._coo_post_feat_img.localFile.childImageSharp.fluid,
  }
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO title={post.title} />
      <TopQuote data={intro} />
      <MainContent data={mainData} />
      {nextPostData && <NextPost data={nextPostData} />}
      {prevPostData && <PrevPost data={prevPostData} />}
    </Layout>
  )
}

export const query = graphql`
  query singlePostQuery($slug: String!) {
    post: wordpressPost(slug: { eq: $slug }) {
      wordpress_id
      title
      slug
      acf {
        _coo_post_excerpt
        _coo_post_post_content
        _coo_post_feat_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    allPosts: allWordpressPost {
      edges {
        node {
          title
          slug
          acf {
            _coo_post_excerpt
            _coo_post_feat_img {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default post
