import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import WordPressWysiwyg from "./WordPressWysiwyg"

const MainContentStyled = styled.div`
  .featuredWrapper {
    position: relative;

    @media (min-width: 768px) {
      max-width: 110rem;
      margin: auto;
      padding: 0;
    }

  }
`

const MainContent = ({ data }) => {
  return (
    <MainContentStyled>
      <div className="featuredWrapper">
        <Img fluid={data.featureImg} alt="" />
      </div>
      <WordPressWysiwyg content={data.wysiwyg} />
    </MainContentStyled>
  )
}

export default MainContent
