import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  medWrapper,
  colors,
  H2White,
  B1White,
  fontSizer,
  buttonThree,
  B1Black,
} from "../../styles/helpers"

const CallOutSection = styled.section`
  .calloutTop {
    width: 100%;
    padding: 4rem 0;
    background-color: ${colors.colorAccent};

    &__wrapper {
      ${medWrapper};
      align-items: center;
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(75% - 4rem);
        margin: 0 2rem;
      }

      @media (min-width: 1025px) {
        width: calc(65% - 4rem);
        margin: 0 2rem;
      }

      h2 {
        ${H2White};
        color: ${colors.greyBrown};
      }

      p {
        ${B1White};
        color: ${colors.greyBrown};
      }

      a {
        @media (max-width: 767px) {
          ${buttonThree};
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-bottom: 2.5rem;
        }

        @media (min-width: 768px) {
          ${B1Black};
          ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
          transition: all 0.3s ease;
          text-transform: uppercase;

          &:hover {
            color: ${colors.colorPrimary};
          }
        }

        span {
          display: none;
          padding-right: 0.5rem;

          @media (min-width: 768px) {
            display: inline-block;
          }
        }
      }
    }

    &__image {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(25% - 4rem);
        margin: 0 2rem;
      }

      @media (min-width: 1025px) {
        width: calc(35% - 4rem);
        margin: 0 2rem;
      }
    }
  }
`

const NextPost = ({ data: { node: post } }) => {
  return (
    <CallOutSection>
      <div className="wrapper">
        <div className="calloutTop">
          <div className="calloutTop__wrapper">
            <div className="calloutTop__content">
              <div>
                <h2 dangerouslySetInnerHTML={{ __html: post.title }} />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: post.acf._coo_post_excerpt }}
              />
              <div>
                <Link to={`/news/${post.slug}`}>
                  <span>&gt;</span>Next Post
                </Link>
              </div>
            </div>
            <div className="calloutTop__image">
              <Img
                fluid={
                  post.acf._coo_post_feat_img.localFile.childImageSharp.fluid
                }
                alt={post.acf._coo_post_feat_img.alt_text}
              />
            </div>
          </div>
        </div>
      </div>
    </CallOutSection>
  )
}

export default NextPost
